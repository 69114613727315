@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

* {
  color: whitesmoke;
}

.ProfilePicture {
  width: auto;
  max-width: 700px;
  height: 90%;
  max-width: 100%;
  max-height: 100%;
  padding-top: 40px;
  padding-left: 40px;
}

.left-col {
  display: flex;
  align-items: center;
}

.right-col {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2%;
  padding-right: 5%;
}

.icon {
  width: 75px;
  height: auto;
  margin: 6% 9% 4%;
}

.about-container {
  display: flex;
  height: 100%;
  padding-bottom: 90px;
}

.about-container {
  background: linear-gradient(
    180deg,
    rgba(33, 37, 41, 1) 1%,
    rgb(68, 113, 95) 80%
  );
}

.hire-button {
  font-size: 2em;
  background-color: rgb(41, 41, 41);
  border: 2px solid white;
  width: 300px;
  height: 100px;
  margin: 50px auto;
}
.hire-button:hover {
  background-color: #777777;
  border: 2px solid #71ff99;
}

.icon:hover {
  fill: #a9fdac;
}

.code {
  font-family: "Fira Code", monospace;
}
h1 {
  font-size: 5em;
}

p.code {
  font-size: 1.9em;
}

.modal-content {
  background-clip: unset;
  border: var(--bs-modal-border-width) solid #212529;
  outline: 1;
  background-color: #212529;
}
.modal {
  --bs-modal-header-border-color: #212529;
  --bs-modal-footer-border-color: #212529;
}

.send-email-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #71ff99;
  border: white;
  margin: 0px auto 10px;
  width: 98%;
  height: 40px;
  font-size: 1.5em;
}
.send-email-button:hover {
  background-color: #04fe4b;
}

.form-control {
  background-color: #2c2c2c;
  color: white;
}

.form-control:focus {
  background-color: #212529;
  color: white;
}

.my-alert {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.alert-text {
  color: #212529;
  margin: auto;
}
